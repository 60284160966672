var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.eqpGroups.length > 0
    ? _c(
        "ul",
        {
          staticClass: "eqp-group-tree",
          class: { "eqp-group-part": _vm.isPartEqpGroup },
        },
        [
          _vm._l(_vm.eqpGroups, function (eqpGroup, index) {
            return [
              eqpGroup
                ? _c(
                    "li",
                    {
                      key: index,
                      class: { "opened-eqp-list": eqpGroup.isOpen },
                    },
                    [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              eqpGroup.isOpen = !eqpGroup.isOpen
                            },
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: eqpGroup.isOpen,
                                  expression: "eqpGroup.isOpen",
                                },
                              ],
                              staticClass: "arrow_down",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/images/arrow_drop_down-black.svg",
                                  alt: "Arrow Down Icon",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !eqpGroup.isOpen,
                                  expression: "!eqpGroup.isOpen",
                                },
                              ],
                              staticClass: "arrow_right",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/images/arrow_right-black.svg",
                                  alt: "Arrow Down Icon",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" " + _vm._s(eqpGroup.name) + " "),
                        ]
                      ),
                      _c(
                        "ul",
                        _vm._l(eqpGroup.equipments, function (eqp, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              class: {
                                disabled:
                                  Number(_vm.$route.query.eqp) === eqp.id,
                                active: _vm.selectedEqp.id === eqp.id,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("selectedEqp", eqp)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(eqp.equipmentName) + " ")]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }