<template>
  <div class="none-eqp-desc">
    <p>
      <b>등록된 설비가 없습니다.</b>
      <span>설비를 등록/승인 한 후, {{ itemName }}를 만들 수 있습니다.</span>
    </p>
    <ul>
      <li>
        * 자체설비 등록 방법
        <ul>
          <li>Step1. 인프라 관리 > 설비그룹 관리 에서 설비그룹 생성</li>
          <li>Step2. 인프라 관리 > 자체설비 관리 에서 설비 생성</li>
        </ul>
      </li>
      <li>
        * 도입설비 승인 방법
        <ul>
          <li>Step1. 인프라 관리 > 도입설비 관리 에서 승인대기 탭 클릭</li>
          <li>Step2. 도입하고자 하는 설비에 대해 승인 처리</li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'NoneEqpDesc',
  props: ['itemName'],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>
<style scoped lang="scss">
@import 'NoneEqpDesc';
</style>
