<template>
  <general-function-popup v-show="isShow" class="copy-popup">
    <h2 slot="title">{{ $t(`message.${title}`) }}</h2>
    <div slot="contents" class="contents">
      <p v-html="$t(`message.${description}`)" />
      <h5>설비 선택</h5>
      <eqp-group-tree
        v-if="eqpGroups"
        :eqp-groups="eqpGroups"
        :selected-eqp="selectedEqp"
        :is-part-eqp-group="true"
        @selectedEqp="onSelectEquipment"
      />
      <ul v-else>
        <li v-for="(comp, key) in eqpGroupsByCompany" :key="key">
          <h6>{{ comp.compTitle }}</h6>
          <eqp-group-tree :eqp-groups="comp.eqpGroups" :selected-eqp="selectedEqp" @selectedEqp="onSelectEquipment" />
        </li>
      </ul>
    </div>
    <button-basic slot="button" color="gray-border-1" @click="onClosePopup">취소</button-basic>
    <button-basic slot="button" :disabled="isEmptyObject(selectedEqp)" @click="onClickConfirmButton">
      {{ $t(`message.${button}`) }}
    </button-basic>
  </general-function-popup>
</template>
<script>
import { mapActions } from 'vuex';
import GeneralFunctionPopup from '@/component/popup/generalFunctionPopup/GeneralFunctionPopup';
import isEmptyObject from '@/asset/js/isEmptyObject';
import EqpGroupTree from '@/component/eqpGroupTree/EqpGroupTree';

export default {
  name: 'CopyPopup',
  props: ['isShow', 'title', 'description', 'button', 'eqpGroups'],
  data() {
    return {
      isEmptyObject: isEmptyObject,
      selectedEqp: {},
      eqpGroupsByCompany: {
        intdEqpGroup: {
          compTitle: '도입설비 그룹',
          eqpGroups: [],
        },
        selfEqpGroup: {
          compTitle: '자체설비 그룹',
          eqpGroups: [],
        },
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    isShow(newVal) {
      this.selectedEqp = {};
      if (newVal && !this.eqpGroups) {
        this.getFactoryEqpsByGroup();
        this.getFactoryEqpsSelfByGroup();
      }
    },
  },
  methods: {
    ...mapActions('factoryEquipments', ['GET_FACTORY_EQPS_BY_GROUP']),
    ...mapActions('factorySelfEquipments', ['GET_FACTORY_EQPS_SELF_BY_GROUP']),
    onClosePopup() {
      this.$emit('close');
    },
    onClickConfirmButton() {
      this.$emit('confirm', this.selectedEqp);
    },
    onSelectEquipment(eqp) {
      this.selectedEqp = eqp;
    },
    async getFactoryEqpsByGroup() {
      const eqpsByGroup = await this.GET_FACTORY_EQPS_BY_GROUP();
      this.eqpGroupsByCompany['intdEqpGroup'].eqpGroups = eqpsByGroup.map((group) => {
        group.isOpen = false;
        return group;
      });
    },
    async getFactoryEqpsSelfByGroup() {
      const selfEqpsByGroup = await this.GET_FACTORY_EQPS_SELF_BY_GROUP();
      this.eqpGroupsByCompany['selfEqpGroup'].eqpGroups = selfEqpsByGroup.map((group) => {
        group.isOpen = false;
        return group;
      });
    },
  },
  components: { EqpGroupTree, GeneralFunctionPopup },
};
</script>
<style scoped lang="scss">
@import 'CopyPopup';
</style>
