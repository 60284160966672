var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "general-function-popup",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      staticClass: "copy-popup",
    },
    [
      _c("h2", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(_vm._s(_vm.$t("message." + _vm.title))),
      ]),
      _c(
        "div",
        {
          staticClass: "contents",
          attrs: { slot: "contents" },
          slot: "contents",
        },
        [
          _c("p", {
            domProps: {
              innerHTML: _vm._s(_vm.$t("message." + _vm.description)),
            },
          }),
          _c("h5", [_vm._v("설비 선택")]),
          _vm.eqpGroups
            ? _c("eqp-group-tree", {
                attrs: {
                  "eqp-groups": _vm.eqpGroups,
                  "selected-eqp": _vm.selectedEqp,
                  "is-part-eqp-group": true,
                },
                on: { selectedEqp: _vm.onSelectEquipment },
              })
            : _c(
                "ul",
                _vm._l(_vm.eqpGroupsByCompany, function (comp, key) {
                  return _c(
                    "li",
                    { key: key },
                    [
                      _c("h6", [_vm._v(_vm._s(comp.compTitle))]),
                      _c("eqp-group-tree", {
                        attrs: {
                          "eqp-groups": comp.eqpGroups,
                          "selected-eqp": _vm.selectedEqp,
                        },
                        on: { selectedEqp: _vm.onSelectEquipment },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
        ],
        1
      ),
      _c(
        "button-basic",
        {
          attrs: { slot: "button", color: "gray-border-1" },
          on: { click: _vm.onClosePopup },
          slot: "button",
        },
        [_vm._v("취소")]
      ),
      _c(
        "button-basic",
        {
          attrs: {
            slot: "button",
            disabled: _vm.isEmptyObject(_vm.selectedEqp),
          },
          on: { click: _vm.onClickConfirmButton },
          slot: "button",
        },
        [_vm._v(" " + _vm._s(_vm.$t("message." + _vm.button)) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }