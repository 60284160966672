<template>
  <ul class="eqp-group-tree" v-if="eqpGroups.length > 0" :class="{ 'eqp-group-part': isPartEqpGroup }">
    <template v-for="(eqpGroup, index) in eqpGroups">
      <li v-if="eqpGroup" :key="index" :class="{ 'opened-eqp-list': eqpGroup.isOpen }">
        <span @click="eqpGroup.isOpen = !eqpGroup.isOpen">
          <button class="arrow_down" v-show="eqpGroup.isOpen">
            <img src="/images/arrow_drop_down-black.svg" alt="Arrow Down Icon" />
          </button>
          <button class="arrow_right" v-show="!eqpGroup.isOpen">
            <img src="/images/arrow_right-black.svg" alt="Arrow Down Icon" />
          </button>
          {{ eqpGroup.name }}
        </span>
        <ul>
          <li
            v-for="(eqp, index) in eqpGroup.equipments"
            :key="index"
            :class="{ disabled: Number($route.query.eqp) === eqp.id, active: selectedEqp.id === eqp.id }"
            @click="$emit('selectedEqp', eqp)"
          >
            {{ eqp.equipmentName }}
          </li>
        </ul>
      </li>
    </template>
  </ul>
</template>
<script>
export default {
  name: 'EqpGroupTree',
  props: ['eqpGroups', 'selectedEqp', 'isPartEqpGroup'],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
@import 'EqpGroupTree';
</style>
