var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "none-eqp-desc" }, [
    _c("p", [
      _c("b", [_vm._v("등록된 설비가 없습니다.")]),
      _c("span", [
        _vm._v(
          "설비를 등록/승인 한 후, " +
            _vm._s(_vm.itemName) +
            "를 만들 수 있습니다."
        ),
      ]),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(" * 자체설비 등록 방법 "),
        _c("ul", [
          _c("li", [
            _vm._v("Step1. 인프라 관리 > 설비그룹 관리 에서 설비그룹 생성"),
          ]),
          _c("li", [
            _vm._v("Step2. 인프라 관리 > 자체설비 관리 에서 설비 생성"),
          ]),
        ]),
      ]),
      _c("li", [
        _vm._v(" * 도입설비 승인 방법 "),
        _c("ul", [
          _c("li", [
            _vm._v("Step1. 인프라 관리 > 도입설비 관리 에서 승인대기 탭 클릭"),
          ]),
          _c("li", [_vm._v("Step2. 도입하고자 하는 설비에 대해 승인 처리")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }